import React from "react";

import Layout from "./src/shared/layout";

import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import firebase from "gatsby-plugin-firebase";

/*
 * Custom fonts
 * Uncomment below if using custom fonts
 */
// import "./static/fonts/fonts.css";

export const shouldUpdateScroll = ({ routerProps, prevRouterProps }) => {
  // return (
  //   routerProps?.location?.pathname?.split("/")[1] !==
  //   prevRouterProps?.location?.pathname?.split("/")[1]
  // );

  return (
    routerProps?.location?.pathname.indexOf("know") < 0 &&
    routerProps?.location?.pathname.indexOf("blog") < 0
  );

  // return true;
};

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}
