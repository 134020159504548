import { ColorRGB, LogoType, isSanityLink } from "@util/types";
import { Dispatch, SetStateAction } from "react";
import { Maybe, SanityBlockContent, SanityLink } from "@graphql-types";

import algoliasearch from "algoliasearch/lite";
import firebase from "gatsby-plugin-firebase";
import { fontSizes } from "@util/constants";
import moment from "moment";
import { toast } from "react-toastify";
import { useHandleQuery } from "@api";
import { sanityClient } from "@lib/sanityClient";

export const isBrowser = () => typeof window !== "undefined";

export function arraysEquality(array1: any[], array2: any[]) {
  return array1.length === array2.length && array1.every((value, index) => value === array2[index]);
}

export function objectEquality(object1: any, object2: any) {
  return Object.keys(object1).every(key => object1[key] === object2[key]);
}

export function splitArrayIntoChunks(array: any[], split: number) {
  const chunks = array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / split);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return chunks;
}

export const em = (fontSize: number, baseSize: number = fontSizes.p.default) => {
  return `${fontSize / baseSize}em`;
};

export const linkType = (link: SanityLink | string | undefined | null) => {
  if (!link) return;

  const linkSlug = isSanityLink(link) ? link.internalLink?.pageMeta?.slug?.current : link;
  const handles = useHandleQuery();

  const arr = ["go", "pro", "know"];

  if (!linkSlug) return;

  const type = arr.find(type => {
    return linkSlug.indexOf(handles[type as LogoType]?.pageMeta?.slug?.current) > -1;
  });

  return type as LogoType;
};

export const blockType = (
  links: Maybe<Maybe<SanityLink>[]> | undefined,
  defaultColor: ColorRGB,
) => {
  if (!links) return defaultColor;

  const linksTypes = links?.map(link => {
    if (!link) return;
    return linkType(link);
  });

  const type = linksTypes.find(type => type != null);

  return type ?? defaultColor;
};

export const toPlainText = (blocks: Maybe<SanityBlockContent> | undefined): string => {
  if (blocks?._rawContent == null) return "";

  return (
    blocks._rawContent
      // loop through each block
      .map(block => {
        if (!block) return "";

        // if it's not a text block with children,
        // return nothing
        if (block._type !== "block" || !block.children) {
          return "";
        }
        // loop through the children spans, and join the
        // text strings
        return block.children.map(child => child.text).join("");
      })
      // join the paragraphs leaving split by two linebreaks
      .join("\n\n")
  );
};

export const toDollar = (price?: Maybe<number>) => {
  if (!price) return;

  const formatter = new Intl.NumberFormat("en-NZ", {
    style: "currency",
    currency: "NZD",
    minimumFractionDigits: 0,
  });

  return formatter.format(price);
};

export const getPackageCategory = (categories: Maybe<Maybe<string>[]> | undefined) => {
  if (categories == null || categories.length === 0) return "pro";
  return categories
    ?.find(cat => {
      if (!cat || cat._key) return;
      console.log({ cat });

      const catLowerCase = cat.toLowerCase();
      return catLowerCase === "go" || catLowerCase === "pro";
    })
    ?.toLowerCase() as LogoType | undefined;
};

export const toDate = (date: string, type: "time" | "month" | "date" | "day") => {
  const dateObj = new Date(date);

  const doubleDigit = (digit: number) => {
    if (digit.toString().length < 2) {
      return `0${digit}`;
    }
    return digit;
  };

  const hours = dateObj.getHours();
  const isNight = hours > 11;

  switch (type) {
    case `time`:
      return `${hours > 12 ? hours - 12 : hours === 0 ? 12 : hours}:${doubleDigit(
        dateObj.getMinutes(),
      )}${isNight ? `pm` : `am`}`;
    case `month`:
      const month = dateObj.getMonth();
      const monthArr = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ];
      return `${monthArr[month]}`;
    case `date`:
      return `${doubleDigit(dateObj.getDate())}`;
    case `day`:
      const day = dateObj.getDay();
      const dayArr = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      return `${dayArr[day]}`;
  }
};

//Alogolia Search client
const algoliaClient = algoliasearch(
  `${process.env.GATSBY_ALGOLIA_APPLICATION_ID}`,
  `${process.env.GATSBY_ALGOLIA_API_KEY}`,
);

export const searchClient = {
  search(requests: any) {
    const shouldSearch = requests.some(({ params: { query } }) => query !== "");
    const facetFilter = requests.some(params => params.params["facetFilters"] !== undefined);

    if (shouldSearch || facetFilter) {
      return algoliaClient.search(requests);
    }

    return Promise.resolve({
      results: [{ hits: [] }],
    });
  },
  searchForFacetValues: algoliaClient.searchForFacetValues,
};

export const handleize = (str?: string | null) => {
  if (str == null) return ``;

  return str
    .toLowerCase()
    .replace("'", "")
    .replace(/[^\w\u00C0-\u024f]+/g, "-")
    .replace(/^-+|-+$/g, "")
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
};

export const emailValidator = (str: string) => {
  if (!str) return false;

  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegex.test(str);
};

export const returnTimestampFromSeconds = (seconds: number | null) => {
  if (seconds == null) return null;
  const date = moment(seconds);
  const formatted = date.format("do MMMM, YYYY | h:mma");
  return formatted;
};

export const addSendInBlueContact = async (body: string) => {
  if (body == null) return null;

  const res = await fetch(`/.netlify/functions/sibAddContact`, {
    method: "POST",
    body: body,
  });
  try {
    const response = await res.json();

    if (response) {
      toast.success(response.message);

      return response;
    }

    if (response.error) {
      toast.error(response.error);

      return response.error;
    }
  } catch (err) {
    throw err;
  }
};

const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export function generateString(length: number) {
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export const getVaultProperty = async (
  propertyId: string,
  setProperty: (property: any) => void,
  setLoaded: Dispatch<SetStateAction<boolean>>,
) => {
  console.log(propertyId);

  try {
    const res = await fetch(`/.netlify/functions/getResidentialProperty`, {
      method: "POST",
      body: JSON.stringify(propertyId),
    });
    const response = await res.json();
    console.log({ response });

    setProperty(response.property);
    setLoaded(true);
  } catch (error) {
    console.log({ error });
  }
};

export const getSanityProperty = async (
  propertyId: string,
  setProperty: (property: any) => void,
  setLoaded: Dispatch<SetStateAction<boolean>>,
) => {
  try {
    const res = await sanityClient.fetch(`*[_type == "property" && _id == $id][0]`, {
      id: propertyId,
    });
    if (res) {
      console.log({ res });

      setProperty(res);
      setLoaded(true);
    }
  } catch (error) {
    console.log({ error });
  }
};
