import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const RegionQuery = () => {
  const { allSanityRegion }: Query = useStaticQuery(graphql`
    {
      allSanityRegion {
        nodes {
          regionName
          regionId
        }
      }
    }
  `);

  const regions = allSanityRegion.nodes
    .map(node => {
      return {
        regionName: node.regionName ?? "",
        regionId: node.regionId ?? -1,
      };
    })
    .filter(region => region.regionId !== -1);

  return regions;
};

export default RegionQuery;
