import styled, { createGlobalStyle } from "styled-components";

import {
  fontSizes,
  colors,
  PRIMARY_COLOR,
  FONT_BODY,
  FONT_BODY_WEIGHT,
  FONT_HEADER,
  FONT_HEADER_WEIGHT,
  fontWeights,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { em } from "@util/helper";

export const GlobalStyle = createGlobalStyle`
  // @import url("https://p.typekit.net/p.css?s=1&k=ufy3oux&ht=tk&f=139.140.175.176.143.144.147.148.156.157.161.162&a=85138683&app=typekit&e=css");
  @import url("https://use.typekit.net/ufy3oux.css");


  @font-face {
    font-family: "proxima-nova";
    src: url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
        format("woff2"),
      url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
        format("woff"),
      url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
        format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-family: "proxima-nova";
    src: url("https://use.typekit.net/af/4de20a/00000000000000007735e604/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
        format("woff2"),
      url("https://use.typekit.net/af/4de20a/00000000000000007735e604/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
        format("woff"),
      url("https://use.typekit.net/af/4de20a/00000000000000007735e604/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
        format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 700;
  }

  @font-face {
    font-family: "proxima-nova";
    src: url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/l?subset_id=2&fvd=n6&v=3")
        format("woff2"),
      url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/d?subset_id=2&fvd=n6&v=3")
        format("woff"),
      url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/a?subset_id=2&fvd=n6&v=3")
        format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 600;
  }  
  
  @font-face {
    font-family: "proxima-nova";
    src: url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("woff2"),
      url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("woff"),
      url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
        format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: "proxima-nova";
    src: url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
        format("woff2"),
      url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
        format("woff"),
      url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
        format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 400;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0px;
    font-family: ${FONT_BODY}, sans-serif;
    font-size: ${fontSizes.p.default}px;
    font-weight: ${FONT_BODY_WEIGHT};
    color: ${PRIMARY_COLOR};
    line-height: 1.6;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }

  body,
  input,
  textarea,
  button,
  select {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
  }

  em {
    font-style: italic;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  img {
    max-width: 100%;
    border: 0;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font: inherit;
    margin: 0;
  }

  button,
  html input {
    &[disabled] {
      cursor: default;
    }
  }

  input {
    &[type="search"],
    &[type="number"],
    &[type="email"],
    &[type="password"] {
      -webkit-appearance: none;
      -moz-appearance: none;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  td,
  th {
    padding: 0;
  }

  textarea {
    overflow: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6 {
    font-family: ${FONT_HEADER}, sans-serif;
    font-weight: ${FONT_HEADER_WEIGHT};
    line-height: 1.2;
    overflow-wrap: break-word;
    word-wrap: break-word;
    margin-top: 0;
    margin-bottom: 20px;
  
    a {
      color: inherit;
      text-decoration: none;
      font-weight: inherit;
    }

    &:last-child{
      margin-bottom: 0;
    }
  }

  h1, .h1{
    font-size: ${em(fontSizes.h1.default)};
    font-weight: ${fontWeights.bold};
  }

  h2, .h2{
    font-size: ${em(fontSizes.h2.default)};
  }

  h3, .h3{
    font-size: ${em(fontSizes.h3.default)};
    font-weight: ${fontWeights.semibold};
  }

  h4, .h4{
    font-size: ${em(fontSizes.h4.default)};
  }

  h5, .h5{
    font-size: ${em(fontSizes.h5.default)};
  }

  h6, .h6{
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  p {
    margin-top: 0;
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: 0.3s ease all;

    &:visited {
      color: currentColor;
    }

    .gatsby-image-wrapper, svg, .static{
      transition: 0.3s ease opacity;
    }
    
    &:hover{
      .gatsby-image-wrapper, svg, .static{
        opacity: 0.75;
      }
    }

    &.buttonLink{
      display: inline-block;
    }
  }

  hr {
    margin: 2em 0;
    border-top: 1px solid ${colors.main};
    width: 100%;
  }

  label {
    vertical-align: middle;
    line-height: 1.2;
    &[for] {
      cursor: pointer;
    }
  }

  input, select, textarea {
    border-radius: 5px;
    border: 1px solid ${colors.main};

    &:active, &:focus {
      outline: inset 1px ${colors.main};
    }
  }

  input{
    padding: 15px 20px;
    line-height: 1.2;

    &[type="radio"],
    &[type="checkbox"] {
      position: absolute;
      pointer-events: none;
      visibility: hidden;
    }

    &[type="radio"],
    &[type="checkbox"] {
      & + label {
        position: relative;
        &:before{
          border: 1.5px solid ${colors.main};
          content: "";
          margin-right: 15px;
          width: 20px;
          height: 20px;
          display: inline-block;
          vertical-align: middle;
          border-radius: 100%;
        }
      }

      &[type="checkbox"],
      &[type="radio"]{
        &:checked {
          & + label {
            &:after{
              position: absolute;
              top: 1px;
              left: 0;
              content: "";
              color: darkgray;
              width: 20px;
              height: 20px;
              display: inline-block;
              border-radius: 100%;
            }
          }
        }
      }
    }

    &[type="checkbox"]{
      &:checked {
        & + label {
          &:after{
            background-color: ${colors.main};
          }
        }
      }
    }

    &[type="radio"]{
      &:checked {
        & + label {
          &:after{
            border: 6px solid ${colors.main};
          }
        }
      }
    }
  }

  select {
    cursor: pointer;
    padding: 15px 55px 15px 20px;
    line-height: 1.2;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 7L11 1' stroke='currentColor'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: calc(100% - 23px);

    &:-ms-expand {
      display: none;
    }
  }

  textarea {
    padding: 15px 20px;
    line-height: 1.2;
  }

  button {
    padding: 0;
    display: inline-block;
    background: none;
    border: none;
    cursor: pointer;
    transition: 0.3s ease all;

    &:hover{
      opacity: 0.8;
    }
  }

  section {
    overflow: hidden;
  }

  .Toastify__toast {
    background: ${colors.white};
    border-radius: 20px;
    color: ${colors.main};
  }
  .Toastify__toast-body{
    padding: 6px 13px;
  }

  .Toastify__toast--error {
    background: ${colors.errorRed};
    color: ${colors.white};

    .Toastify__toast-icon,
    .Toastify__close-button--light{
      svg{
        fill: ${colors.white};
      }
    }
    .Toastify__progress-bar--error{
      background: #631b13;
    }
  }

  .capitalize {
    text-transform: capitalize;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    body {
      font-size: ${fontSizes.p.mobile}px;
    }

    h1, .h1{
      font-size: ${em(fontSizes.h1.mobile, 14)};
    }
  
    h2, .h2{
      font-size: ${em(fontSizes.h2.mobile, 14)};
    }
  
    h3, .h3{
      font-size: ${em(fontSizes.h3.mobile, 14)};
    }
  
    h4, .h4{
      font-size: ${em(fontSizes.h4.mobile, 14)};
    }
  
    h5, .h5{
      font-size: ${em(fontSizes.h5.mobile, 14)};
    }
  }

`;

export const Main = styled.main<{}>``;
