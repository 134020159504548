const client = require("@sanity/client");

import imageUrlBuilder from "@sanity/image-url";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";

export const sanityClient = client({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  useCdn: false,
  apiVersion: "v1",
  token: process.env.GATSBY_SANITY_TOKEN,
});

export const imageBuilder = imageUrlBuilder(sanityClient).auto("format");

export function urlForImageRef(source?: SanityImageSource) {
  return imageBuilder.image(source ?? "");
}
